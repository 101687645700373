import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
//withRoute HoC allows access to the router params, so we know what the route is

import { NavigationItemWrapper } from "./NavigationItemStyle";

const navigationItem = (props) => {

    return (
        <NavigationItemWrapper itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            <NavLink
            to={"/" + props.slug}
            className={props.match.params.inputSlug === props.slug ? 'active' : null}>
            {props.name}
            </NavLink>
        </NavigationItemWrapper>
    );
};

export default withRouter(navigationItem);