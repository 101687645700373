import styled from 'styled-components';
import { device } from '../helpers/device';

export const LayoutWrapper = styled.div`
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    text-align: center;

    width: 100%;

    @media ${device.desktop} {
        max-width: 1300px;
    }
`;