import React from 'react';

import { EraseCookie } from "../../../hoc/helpers/cookie";

const CheckoutComplete = () => {

    //Don't forget to erase cookie
    //EraseCookie('pbwBasketContents');

    return <p>Checkout complete! Happy face.</p>
}

export default CheckoutComplete;