import React from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { AboutUsWrapper } from './AboutUsStyle';

const aboutUs = () => {
    return <Layout>
      <AboutUsWrapper>
    <p>About us...</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eget nulla facilisi etiam dignissim diam quis enim lobortis. Est ullamcorper eget nulla facilisi. Odio ut enim blandit volutpat maecenas volutpat blandit aliquam. Risus feugiat in ante metus dictum. Nunc sed id semper risus. Massa placerat duis ultricies lacus sed turpis tincidunt id. Ac auctor augue mauris augue. Accumsan sit amet nulla facilisi. Consequat mauris nunc congue nisi vitae. Ipsum nunc aliquet bibendum enim facilisis. Vitae elementum curabitur vitae nunc sed velit dignissim. Nisl purus in mollis nunc sed id. Ac felis donec et odio pellentesque.</p>
    <p>Morbi enim nunc faucibus a pellentesque. Pretium vulputate sapien nec sagittis aliquam malesuada bibendum arcu. Nibh cras pulvinar mattis nunc sed. Cursus sit amet dictum sit amet justo donec. Sapien eget mi proin sed libero enim sed faucibus. Mollis nunc sed id semper. Diam donec adipiscing tristique risus nec feugiat in fermentum. Ullamcorper eget nulla facilisi etiam. Urna porttitor rhoncus dolor purus non. Nullam eget felis eget nunc lobortis mattis aliquam faucibus purus. Morbi blandit cursus risus at ultrices mi. Diam volutpat commodo sed egestas egestas fringilla phasellus faucibus scelerisque. Arcu non sodales neque sodales ut etiam sit. Eget arcu dictum varius duis at consectetur lorem donec massa. Nulla aliquet enim tortor at auctor urna nunc id cursus. Neque convallis a cras semper. Ornare arcu dui vivamus arcu felis.</p>
    <p>Euismod nisi porta lorem mollis aliquam. Commodo nulla facilisi nullam vehicula ipsum a arcu cursus vitae. Elementum pulvinar etiam non quam lacus suspendisse faucibus interdum. Adipiscing elit pellentesque habitant morbi tristique senectus et netus et. Tincidunt nunc pulvinar sapien et ligula ullamcorper. Amet luctus venenatis lectus magna fringilla urna. Auctor neque vitae tempus quam pellentesque nec nam aliquam. Pharetra massa massa ultricies mi quis hendrerit dolor magna eget. Dolor purus non enim praesent elementum facilisis. Elementum eu facilisis sed odio morbi quis commodo. Sed adipiscing diam donec adipiscing tristique risus nec feugiat in. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Ullamcorper malesuada proin libero nunc consequat interdum varius.</p>
    <p>Quis lectus nulla at volutpat diam. Ultrices sagittis orci a scelerisque purus. Volutpat blandit aliquam etiam erat velit scelerisque in. Non quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor. Semper auctor neque vitae tempus quam. Risus ultricies tristique nulla aliquet enim tortor at auctor urna. Volutpat diam ut venenatis tellus in. Odio eu feugiat pretium nibh. In nibh mauris cursus mattis molestie a. Eu facilisis sed odio morbi quis commodo odio aenean sed. A lacus vestibulum sed arcu non odio euismod lacinia at. Enim tortor at auctor urna nunc id. Sapien eget mi proin sed. Suscipit tellus mauris a diam maecenas sed.</p>
    <p>Fusce ut placerat orci nulla pellentesque. Diam in arcu cursus euismod quis viverra nibh cras. Velit euismod in pellentesque massa placerat duis ultricies lacus sed. Lacus vestibulum sed arcu non. Nunc faucibus a pellentesque sit amet. Eget gravida cum sociis natoque penatibus et magnis dis. Egestas pretium aenean pharetra magna ac placerat vestibulum. Facilisi cras fermentum odio eu feugiat pretium nibh ipsum consequat. Mauris nunc congue nisi vitae suscipit tellus mauris. Nunc lobortis mattis aliquam faucibus purus in massa tempor.</p>
    </AboutUsWrapper>
  </Layout>
}

export default aboutUs;