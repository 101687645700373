import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom'

import UserContext from '../../contexts/UserContext';

const LoginRedirect = (props) => {

    /*
    * Custom redirect to do the setCurrentUser - doing from inside the Apollo Mutation
    * causes problems (hooks inside conditions / loop not allowed)
    */

   let redirectUrl = '/';

   //Try to redirect to the previous location, if it's given
   //Blacklist urls that we don't want to redirect to
   const disallowedRedirects = ['/logout', '/account/new-account'];

   if (props.locationState && disallowedRedirects.indexOf(props.locationState.prevPathName) === -1) {
       redirectUrl = props.locationState.prevPathName;
   }

    const { setCurrentUser } = useContext(UserContext);
    setCurrentUser(props.user);

    return <Redirect to={redirectUrl} />

}

export default LoginRedirect;