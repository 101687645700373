import styled from 'styled-components';

export const BasketProductOptionWrapper = styled.div`

    display: block;
    text-align: left;

    .helperText {
        font-size: 0.8rem;
        color: ${props => props.error ? '#F00' : '#000'};
    }

`