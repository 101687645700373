import React from 'react';
import { useQuery } from '@apollo/react-hooks'

import { NavigationItemsWrapper } from "./NavigationItemsStyle";

import NavigationItem from './NavigationItem/NavigationItem';

import { QUERY_PRODUCT_CATEGORIES } from '../../../db_calls/products';

const NavigationItems = (props) => {

  const { loading, error, data } = useQuery(QUERY_PRODUCT_CATEGORIES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const navitems = data.productCategories.map(({ id, name, slug }) => (
    <NavigationItem
      key={id}
      name={name}
      slug={slug}
    />
  ));

  return (
    <NavigationItemsWrapper itemScope itemType="http://schema.org/ItemList">
      {navitems}
    </NavigationItemsWrapper>
  );

};

export default NavigationItems;