import React from 'react';

const CheckoutCancelled = () => {

    //Don't forget to erase cookie
    //EraseCookie('pbwBasketContents');

    return <p>Checkout cancelled. Sad face.</p>
}

export default CheckoutCancelled;