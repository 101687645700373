import styled, { css } from 'styled-components';
import { device } from '../../hoc/helpers/device';

export const CategoryWrapper = styled.main`

    section {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    @media ${device.tablet} {
    }

    @media ${device.laptop} {
    }

    @media ${device.desktop} {
    }

`