import React from 'react';

import { Link } from 'react-router-dom';
import { ProductWrapper } from './ProductStyle';

const product = (props) => {

    return (
        <ProductWrapper itemScope itemType="http://schema.org/ListItem">
            <h3>{props.name}</h3>
            <Link
            itemProp="url"
            to={props.slug}>
                {props.name}
            </Link>
        </ProductWrapper>
    );
};

export default product;