import React from 'react';
import { useMutation } from '@apollo/react-hooks'

import ForgottenAccountPasswordForm from './ForgottenAccountPasswordForm';

import { MUTATION_UPDATE_USER_PASSWORD } from '../../db_calls/users';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { ConfirmPageWrapper } from './ConfirmAccountStyle';

const ConfirmAccount = (props) => {

    const [
        updateUserPassword,
        { loading, error, called, data }
    ] = useMutation(MUTATION_UPDATE_USER_PASSWORD);

    if (loading || error || !called) {
        return <ForgottenAccountPasswordForm
                    loading={loading}
                    error={error}
                    token={props.token}
                    update={updateUserPassword}
                    />
    }

    //Otherwise, assume success
    return <p>Password updated successfully!</p>;

}

export default ConfirmAccount;