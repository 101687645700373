import styled from 'styled-components';

export const NavigationItemWrapper = styled.li`
    padding: 5px;

    a {

        text-decoration: none;
        color: #000;
    }

    a:hover {
        color: #000;
        border: 1px solid #000;
    }

    a:visited {
        color: #000;
    }

    a.active {
        font-weight: bold;
    }

`