import styled, { css } from 'styled-components';
import { device } from '../../../hoc/helpers/device';

const desktopStyles = css`
    section {
        justify-content: space-between;

        div {
            width: 50%;
        }

        div:first-child, div:last-child {
            display: flex;
            width: 25%;
        }
    }

    nav {
        display: block;
        position: ${props => props.navBarLocked ? 'fixed' : 'relative'};
    }

    .head {
        height: ${props => props.navBarLocked ? '175px' : '150px'};
    }
`;

export const HeaderWrapper = styled.header`
    background-color: #DDD;
    text-align: center;

    section {
        display: flex;
        flex-direction: row;
        justify-content: center;

        div {
            background-color: #666;
            width: 100%;
            border: 1px solid #000;
        }

        div:first-child, div:last-child {
            display: none;
            width: 25%;
            text-align: left;
            flex-direction: column;
        }
    }

    nav {
        display: none;
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #888;
    }

    .head {
        height: 25px;
    }

    @media ${device.tablet} {
        ${desktopStyles}
    }

    @media ${device.laptop} {
        ${desktopStyles}
    }

    @media ${device.desktop} {
        ${desktopStyles}
    }
`