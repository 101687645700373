import React, { useState } from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';
import Input from '../../components/UI/Input/Input';

const ForgottenAccountPasswordForm = (props) => {

    const update = (event) => {
        event.preventDefault();
        console.log('Submit it');
        props.register({ variables: {
            password
        } });
    }

    const [password, setPassword] = useState("");

    //If fields are modified after validation failure, clear validation error
    const customSetState = (e, fieldName) => {
        props.fieldErrors[fieldName] = null;

        if (fieldName === 'password') {
            setPassword(e.target.value);
        }
    }

    const loadingMessage = props.loading ? <p>Loading</p> : null;

    return <Layout>
        <p>Form to go here</p>
    </Layout>
}

export default ForgottenAccountPasswordForm;