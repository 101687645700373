import React from 'react';

const gallery = (props) => {

    return <div>
        <p>Gallery here pls</p>
    </div>

};

export default gallery;