import React, {Component} from 'react';

import Aux from '../../hoc/Aux/Aux';
import Drawer from '../../components/Nagivation/Drawer/Drawer';
import Footer from '../../components/Nagivation/Footer/Footer';
import Header from '../../components/Nagivation/Header/Header';
import Toolbar from '../../components/Nagivation/Toolbar/Toolbar';
import { LayoutWrapper } from './LayoutStyle';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

class Layout extends Component {

    state = {
        showSideDrawer: false,
        lockNavBar: false,
        showToolbar: window.innerWidth <= 767 ? true : false
        //basketContents: [] //Not sure this needs to be here
    }

    targetElement = null;

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
        window.addEventListener('resize', this.handleResize, false);

        //Set target for scroll lock
        this.targetElement = document.querySelector('#drawer_nav');

        //Get basket contents from cookie

    }
    
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
        window.removeEventListener('resize', this.handleResize, false);

        clearAllBodyScrollLocks();
    }

    handleScroll = () => {
        if (window.pageYOffset >= 150) { //150 is the height of the image section of the header
            if (this.state.lockNavBar === false) {
                console.log('Lock nav bar');
                this.setState({lockNavBar: true});
            }
        } else {
            if (this.state.lockNavBar === true) {
                console.log('Unlock nav bar');
                this.setState({lockNavBar: false});
            }
        }
    }

    handleResize = () => {

        if (window.innerWidth <= 767) { //767 is the mobile breakpoint (see device.js)
            if (this.state.showToolbar === false) {
                console.log('Show toolbar');
                this.setState({showToolbar: true});
            }
        } else {
            if (this.state.showToolbar === true) {
                console.log('Hide toolbar');
                this.setState({showToolbar: false});
            }
        }
    }
    
    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false});
        enableBodyScroll(this.targetElement); //Enable body scroll
    }

    sideDrawerToggleHandler = () => {
        //Clean way of setting state variable depending on its current
        this.setState( (prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
        //Disable / enable body scroll
        if (this.state.showSideDrawer) {
            enableBodyScroll(this.targetElement);
        } else {
            disableBodyScroll(this.targetElement);
        }
    }

    render () {

        const toolbar = <Toolbar
            drawerToggleClicked={this.sideDrawerToggleHandler}
            drawerCloseClicked={this.sideDrawerClosedHandler} />

        return (
            <Aux>
            <LayoutWrapper>
                <Header
                navBarLocked={this.state.lockNavBar}
                showUser={!this.state.showToolbar}
                //basket={this.state.basketContents}
                />
                <Drawer
                    open={this.state.showSideDrawer}
                    drawCloseClicked={this.sideDrawerClosedHandler} />
                {this.props.children}
            </LayoutWrapper>
            { this.state.showToolbar ? toolbar : null }
            <Footer>
            </Footer>
            </Aux>
        );
    }

};

export default Layout;