import React from 'react';
import { useMutation } from '@apollo/react-hooks'

import Layout from '../../hoc/Layout/Layout';
import ConfirmAccountPage from './ConfirmAccountPage';
import ConfirmAccountSuccess from './ConfirmAccountSuccess';

import { MUTATION_CONFIRM_USER } from '../../db_calls/users';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { ConfirmPageWrapper } from './ConfirmAccountStyle';

const ConfirmAccount = (props) => {

    const [
        confirmUser,
        { loading, error, called, data }
    ] = useMutation(MUTATION_CONFIRM_USER);

    if (loading || !called) {
        return <ConfirmAccountPage
                    token={props.match.params.confirmationToken}
                    confirm={confirmUser}
                    />
    }

    if (error) {
        return <p>Redirect to an error</p>
    }

    //Otherwise, assume success
    return <p>Account confirmed successfully!</p>;

}

export default ConfirmAccount;