import styled from 'styled-components';
import { device } from '../../../hoc/helpers/device';

export const FooterWrapper = styled.footer`
    margin: 0 auto;

    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    flex-wrap: wrap;

    background-color: #222;
    text-align: left;

    width: 100%;
    max-width: 1300px;

    #footerSpacer {
        background-color: #FFF;
        height: 56px; //Same as the height of the toolbar
    }

    section {
        display: inherit;

        ul {
            flex-direction: column;

            li {

                a {
                    color: #EEE;
                }

            }

        }
    }

    section:first-child {

        width: 100%;

        ul {
            display: inherit;
            flex-direction: row;
            width: 100%;
            justify-content: center;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

    }
      
    section:not(:first-child) {

        flex: 1;

        ul {
            display: inherit;
        }
    }

    @media ${device.tablet} {
        flex-direction: row;

        #footerSpacer {
            height: 0;
        }
    }

    @media ${device.laptop} {
        flex-direction: row;

        #footerSpacer {
            height: 0;
        }
    }

    @media ${device.desktop} {
        flex-direction: row;

        #footerSpacer {
            height: 0;
        }
    }

`