import React from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { NewAccountSuccessWrapper } from './NewAccountStyle';

const newAccountSuccess = () => {
    return <Layout>
      <NewAccountSuccessWrapper>
    <h2>Account created</h2>
    <p>Your account has been successfully created</p>
    <p>In order to activate it, please follow the link on the email that has been sent to the email address used when signing up</p>
    </NewAccountSuccessWrapper>
  </Layout>
}

export default newAccountSuccess;