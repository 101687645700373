import React, { useContext } from 'react';
import UserContext from '../../contexts/UserContext';

import { UserWrapper } from "./UserStyle";

import GuestAccount from "./GuestAccount/GuestAccount";
import UserAccount from "./UserAccount/UserAccount";
import Basket from "./Basket/Basket";

const User = (props) => {

    console.log('Rendering user');
    console.log(props);

    //Access currentUser from context
    const { currentUser } = useContext(UserContext);

    //Guest components are the default result
    let accountComponent = <GuestAccount linkType={props.linkType} clicked={props.clicked}/>;
    let basketComponent = <Basket linkType={props.linkType} />;

    if (currentUser.id && currentUser.id > 0) {
        console.log('User.js says CurrentUser found');
        console.log(currentUser);
        accountComponent = <UserAccount currentUser={currentUser} />;
    }

    return (
    <UserWrapper>
        {props.children}
        {accountComponent}
        {basketComponent}
    </UserWrapper>
    );

};

export default User;