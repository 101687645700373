import React, { useState } from 'react';

import Aux from '../../hoc/Aux/Aux';
import Input from '../UI/Input/Input';

import { FindOptionDetailPrice } from "../../hoc/helpers/misc";

const ProductOptionsForm = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submit buttton clicked');

        /*
         * Trying to construct something like:
         * productData:"[{\"productId\": \"1\", \"options\": [ { \"id\":1, \"detailId\":4, \"detailContent\":\"apple\" } ]}]"
         */

        const product = {};
        product.productId = props.productId;
        product.qty = state.qty;
        product.options = [];

        //We need to access the form properties so we can build the JSON productData value that gets used by the mutation
        //Loop through form options
        for (const option of props.options) {

            console.log(`${option.name} = ${state[option.name]}`);
            
            //If the option's prerequisite(s) contain data
            let prerequisiteDataFilled = true;
            if(option.prerequisiteOptions.length) {
                for (const prereq of option.prerequisiteOptions) {
                    const prereqName = props.options.find(o => o.id === prereq.id).name;
                    if (!state[prereqName]) {
                        console.log(prereqName + ' not filled out');
                        prerequisiteDataFilled = false;
                    }
                    
                }
            }

            if (prerequisiteDataFilled) {
                switch (option.type.controlType) {
                    case 'select':
                        console.log('Option =' + state[option.name]);
                        //if (state[option.name] === '0' || (!props.options.find(o => o.name === option.name).required && !state[option.name])) {
                        if (!state[option.name] || state[option.name] === '0') {
                            //Do nothing (don't add to request)
                        } else {
                            console.log('Adding to request');
                            product.options.push({
                                "id": Number(option.id),
                                "detailId": Number(state[option.name])
                            });
                        }
                        break;
                    default: //Default = text
                        product.options.push({
                            "id": Number(option.id),
                            //Get detailId (since there can be only one detail in a text option) from the props
                            "detailId": Number(props.options.find(o => o.name === option.name).details.find(d => d).id),
                            "detailContent": state[option.name]
                        });
                }
            }
            
        }

        const productData = JSON.stringify([product]);

        props.addToBasket({ variables: {
            productData
        } });
    }

    //The below creates an object based on the product's inital options:
    //const [state, setState] = useState({ option 1 name: "", option 2 name: "" });
    let stateObject = {};
    let basePrice = props.productBasePrice;

    const [state, setState] = useState(stateObject);
    const [price, setPrice] = useState(basePrice);

    for (const option of props.options) {

        let optionPrice = 0;

        let cookieOption = null;
        if (props.cookieBasketProduct) {
            cookieOption = props.cookieBasketProduct.options.find(cbpo => Number(cbpo.id) === Number(option.id));
        }

        if (cookieOption && cookieOption.selected) {
            if (option.type.controlType === "select") {
                stateObject[option.name] = cookieOption.selected.id;
            } else {
                stateObject[option.name] = cookieOption.selected.content;
            }
            //Find the price of the selected option so we can add it to the product base price
            console.log(option, cookieOption.selected.id);
            optionPrice = FindOptionDetailPrice(option, cookieOption.selected.id);
        } else {
            stateObject[option.name] = "";
        }
        
        basePrice += optionPrice;

    }

    stateObject.qty = 1; //Default qty

    if (props.cookieBasketProduct) {
        stateObject.qty = props.cookieBasketProduct.qty;
    }
/*
    //STATE STUFF HERE ORIGINALLY
    const [state, setState] = useState(stateObject);
    const [price, setPrice] = useState(basePrice);
*/
    //If fields are modified after validation failure, clear validation error
    const customSetState = (e) => {
        const { name, value } = e.target;
        props.fieldErrors[name] = null;
        console.log(name, value);
        setState(prevState => ({ ...prevState, [name]: value }))

        //Update product price, passing the property that has just been changed (as it won't be in the state yet)
        setPrice(updateProductPrice(e.target));

        props.galleryImageTest(); //Trigger gallery update if an image for the selected options exists
    }

    const updateProductPrice = (e) => {
        console.log('Recalculate product price?')

        const { name, value } = e; //Destructure to get the name and value of the option just updated
        
        //Take product base price
        let totalProductPrice = props.productBasePrice;
        //Loop through each product option
        for (const option of props.options) {

            let detailId = state[option.name];

            if (option.name === name) { //If the most recently updated option, get from value rather than state
                detailId = value;
            }

            totalProductPrice += FindOptionDetailPrice(option, detailId);
        }

        return totalProductPrice;
    }

    const loadingMessage = props.loading ? <p>Loading</p> : null;

    const options = props.options.map(option => {

        //If the option's prerequisites are all empty, don't display or disable the input
        let enableOption = true; //Default to true

        for (const prereq of option.prerequisiteOptions) {
            const prereqOption = props.options.find(p => p.id === prereq.id);
            if (
                (prereqOption.type.controlType === 'select' && prereqOption.required && state[prereqOption.name] === '') ||
                (prereqOption.type.controlType === 'text' && state[prereqOption.name] === '')
            ) {
                //set enabled to false and break out (as all need to be filled for the option to be enabled)
                enableOption = false;
                break;
            }
        }

        return <Input
            key={option.id}
            label={option.description}
            value={state[option.name]}
            onChange={e => customSetState(e)}
            type={option.type.controlType}
            options={option.details}
            name={option.name}
            required={option.required}
            disabled={!enableOption}
            error={props.fieldErrors[option.name] ? props.fieldErrors[option.name].message : null}
        />
    })
    
    const submitButtonText = props.cookieBasketProduct ? "Update product in basket" : "Add to basket"

    return <Aux>
        <form onSubmit={handleSubmit} noValidate>
            {options}
            <Input
                label='Quantity'
                name='qty'
                value={state.qty}
                onChange={e => customSetState(e)}
                type='text'
                required={true}
                error={props.fieldErrors.qty ? props.fieldErrors.qty.message : null}
            />
            <p>Total price per item = &pound;{price.toFixed(2)}</p>
            <button>{submitButtonText}</button>
        </form>
        {loadingMessage}
        {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}
    </Aux>
    
}

export default ProductOptionsForm;