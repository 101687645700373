import React from 'react';
import { useQuery } from '@apollo/react-hooks'

import ForgottenAccountPassword from './ForgottenAccountPassword';

import { QUERY_VALIDATE_UPDATE_USER_PASSWORD_TOKEN } from '../../db_calls/users';

const ForgottenAccountPasswordPage = (props) => {

  //Query checks that the given forgottenPasswordToken exists
  //If ok, loads Mutation component
  //Mutation loads form, with submit triggering the mutation itself

  const { loading, error } = useQuery(props.match.params.passwordUpdateToken());

  if (loading) return <p>Loading...</p>;

  if (error) {
    //Return general site error page
    return <p>Error :(</p>
  }

  return <ForgottenAccountPassword token={props.match.params.passwordUpdateToken}/>

};

export default ForgottenAccountPasswordPage;