import React from 'react';
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'

import Aux from '../../hoc/Aux/Aux';
import Layout from '../../hoc/Layout/Layout';
import AddressForm from './AddressForm/AddressForm';

/*
 * NOTE: CHECKOUT IS CURRENTLY ACCESSIBLE BY THE URL, NEEDS TO ONLY BE WORKING WHEN YOU GO VIA THE BASKET
 * THOUGHT THIS HAD BEEN DONE? CHECK DELETED CODE
 */

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { GetCookie, SetCookie } from "../../hoc/helpers/cookie";
import { GetCookieBasket } from "../../hoc/helpers/misc";

import { MUTATION_TRANSACT, MUTATION_TRANSACT_WITH_ADDRESS } from '../../db_calls/transactions';

//import { TermsWrapper } from './TermsStyle';

const Checkout = (props) => {

    const basketRedirect = <Redirect to="/basket" />
    let cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));

    //Setup plain old mutation (no address)
    const [
        transact,
        { loading: Tloading, error: Terror, called: Tcalled, data: Tdata }
    ] = useMutation(MUTATION_TRANSACT);

    //Setup transact with address mutation
    const [
        transactWithAddress,
        { loading: TWAloading, error: TWAerror, called: TWAcalled, data: TWAdata }
    ] = useMutation(MUTATION_TRANSACT_WITH_ADDRESS);
    
    //Calculate total price of order
    let totalBasketPrice = 0;
    for (const product of cookieBasket.products) {

        const totalItemPrice = (product.price + product.totalOptionsPrice) * product.qty;
        totalBasketPrice += totalItemPrice - (product.totalVoucherDiscount ? product.totalVoucherDiscount : 0);
    
    }
    console.log('totalBasketPrice', totalBasketPrice);


    if (totalBasketPrice > 0) { //If transaction price is greater than 0
    
        //Don't process if the transact with address mutation has been called
        if (!Tcalled) {

            console.log('T mutation not called');
            //Basket had a price of more than 0, so attempt payment
            if (cookieBasket.paymentMethod === 'paypal') {

                //Do transact mutation (no user input req'd)

                //Separate data from cookie
                const productData = JSON.stringify(cookieBasket.products);
                const productVoucherCode = (cookieBasket.productVoucher ? cookieBasket.productVoucher.code : null);

                //Include the product data so the mutation can use it
                transact({ variables: {
                    productData,
                    productVoucherCode
                } });
                
            }

            return 'Loading, please wait';

        } else if (Tcalled && (Tloading || Terror)) {

            //Display a loading message
            return 'Contacting payment merchant';

        } else {

            //Tcalled, not loading, no error - mutation is complete!

            //Redirect to wherever is needed
            
            if (Tdata.transact.url.indexOf('https://') === -1) {
                //if basket, set session storage to let basket know we want to only display the error once
                if (Tdata.transact.url === '/basket') {
                    sessionStorage.setItem('checkoutError', '1');
                }

                return <Redirect push to={{
                    pathname: Tdata.transact.url,
                    state: {
                        data: Tdata.transact.data
                    }
                }} />

            } else {
                //If the redirect URL contains https://, do a history change
                window.location.replace(Tdata.transact.url);
            }

        }



    } else if (totalBasketPrice === 0) { //If transaction price is 0

        //If the mutation hasn't been called (ie, the first time the checkout page is loaded)
        if (!TWAcalled) {
            console.log('TWA mutation not called');

            //If no action (set by basket checkout) or basket is empty
            if (!props.location.state || props.location.state.data === null || cookieBasket.products.length === 0) {

                console.log('Back to basket pls');
                return (basketRedirect)

            }

        props.location.state.data = null; //Wipe state so it can't be re-used
        }

        /*
        * Cash purchases could do something similar to below, except address isn't even required!
        * Probably not safe to have that option on production though
        */

        let fieldErrors = {};

        //Mutation actions
        if (TWAloading || TWAerror || !TWAcalled) {

            if (TWAerror) {
                console.log(TWAerror.graphQLErrors);
        
                for (let graphQLErrorKey in TWAerror.graphQLErrors) {
                    //Could be a validation error
                    //If exception(s) exist, loop through those
                    if (TWAerror.graphQLErrors[graphQLErrorKey].extensions.exception) {
                        for (let invalidArgKey in TWAerror.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs) {
                            fieldErrors[TWAerror.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].param] = {
                                graphQLErrorKey: graphQLErrorKey,
                                message: TWAerror.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].msg
                            }
                        }
                    }
                }

                //Other errors?
        
            }
        }

        if (!TWAcalled || TWAloading || TWAerror) {

            return <AddressForm
                transact={transactWithAddress}
                productData={cookieBasket}
                loading={TWAloading}
                error={TWAerror}
                fieldErrors={fieldErrors}
                />;

        } else {

                //Response *should* be a redirect, so redirect with data
                console.log('Redirecting to '+TWAdata.transactWithAddress.url);

                //if basket, set session storage to let basket know we want to only display the error once
                if (TWAdata.transactWithAddress.url === '/basket') {
                    sessionStorage.setItem('checkoutError', '1');
                }

                return <Redirect push to={{
                    pathname: TWAdata.transactWithAddress.url,
                    state: {
                        data: TWAdata.transactWithAddress.data
                    }
                }} />

        }

    }

    //Do call to same call as basket uses. If any errors, boot back to basket to display them.

    //IF ALL IS GOOD, WE NEED TO REMOVE THE CHOSEN QTY FROM PRODUCT AND VOUCHER


}

export default Checkout;