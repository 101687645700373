import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from "apollo-boost";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter} from 'react-router-dom';

import { GetCookie } from "./hoc/helpers/cookie";
const jwtAuthToken = GetCookie('jwtAuthToken');

//React-axe accessibility testing
if (process.env.REACT_APP_ENV !== 'production') {
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
};

//Include authentication on every request
const client = new ApolloClient({
    uri: process.env.REACT_APP_ENV == 'production' ? "https://api.papabearwear.com/graphql" : "http://localhost:4000/graphql",
    request: operation => {
      operation.setContext({
            headers: {
            authorization: jwtAuthToken ? `Bearer ${jwtAuthToken}` : ''
            }
        });
    }
});

ReactDOM.render(
    <BrowserRouter>
          <ApolloProvider client={client}>
            <App
                jwtAuthToken={jwtAuthToken} />
        </ApolloProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
