export function MakeSimpleToken(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function ParseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export function FindOptionDetailPrice(option, detailId) {
    const selectedDetail = option.details.find(d => d.id === detailId);
    let defaultPrice = 0;
    if (selectedDetail) {
        defaultPrice = selectedDetail.productPriceModifier;
    }
    return defaultPrice;
}

export function GetCookieBasket(cookieBasketJSON) {
    let cookieBasket;
    
    if (!cookieBasketJSON) {
        cookieBasket = {
            products: []
        }
    } else {
        cookieBasket = JSON.parse(cookieBasketJSON);
        console.log("BASKET = ", cookieBasket);
        //Check cookieBasket looks the right 'shape'
        //Does cookieBasket have a products property? If not, it's broken, reset
        if (!cookieBasket.products) {
            cookieBasket = {
                products: []
            }
        }
    }
    return cookieBasket;
}