import styled from 'styled-components';
import { device } from '../../hoc/helpers/device';

export const ProductWrapper = styled.div`

    nav.breadcrumb {
        text-align: left;

        ul {
            padding: 0;
            margin: 0;
    
            li {
                display: inline;
            }
        }

    }

    main {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    section {
        border: 1px solid #000;
        background-color: #EEE;
        width: 100%;
    }

    @media ${device.tablet} {

        main {
            flex-direction: row;
        }

        section {
            width: 50%;
        }

    }

    @media ${device.laptop} {

        main {
            flex-direction: row;
        }

        section {
            width: 50%;
        }

    }

    @media ${device.desktop} {

        main {
            flex-direction: row;
        }

        section {
            width: 50%;
        }

    }


`