import React from 'react';
import { Link } from 'react-router-dom';

import { FooterWrapper } from './FooterStyle';
import UnorderedList from '../../Element/List/UnorderedList';

const footer = (props) => {

    const socialMediaLinks = [
        { text: 'Facebook', type: 'external', url: 'http://www.facebook.com' },
        { text: 'Instagram', type: 'external', url: 'http://www.facebook.com' },
        { text: 'Twitter', type: 'external', url: 'http://www.facebook.com' }
    ];

    const informationLinks = [
        { text: 'About Papa Bear Wear', url: '/about' },
        { text: 'Terms', url: '/terms' },
        { text: 'Cookies', url: '/cookies' }
    ];

    const helpfulLinks = [
        { text: 'Contact us', url: '/contact-us' },
        { text: 'Returns', url: '/returns' },
    ]

    return (
        <FooterWrapper>
            <section>
                <UnorderedList listItems={socialMediaLinks}/>
            </section>
            <section>
                <UnorderedList listItems={informationLinks}/>
            </section>
            <section>
                <UnorderedList listItems={helpfulLinks}/>
            </section>
            <div id="footerSpacer"></div>
        </FooterWrapper>
    );
};

export default footer;