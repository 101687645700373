import React from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { RequestForgottenAccountPasswordWrapper } from './RequestForgottenAccountPasswordStyle';

const requestForgottenAccountPasswordSuccess = () => {
    return <Layout>
      <RequestForgottenAccountPasswordWrapper>
    <h2>Reminder sent</h2>
    <p>If an account exists with the given email address, a forgotten password link will shortly be emailed.</p>
    </RequestForgottenAccountPasswordWrapper>
  </Layout>
}

export default requestForgottenAccountPasswordSuccess;