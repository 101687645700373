import React, { useState } from 'react';

import Layout from '../../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';
import Input from '../../../components/UI/Input/Input';

const AddressForm = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submit');
        
        //The data to send to the mutation
        //productData contains everything, so need to separate
        const productData = JSON.stringify(props.productData.products);
        const productVoucherCode = (props.productData.productVoucher ? props.productData.productVoucher.code : null);

        console.log('ProductData', productData);

        const userContactDetails = {
            email: email,
            salutation: salutation,
            firstName: firstName,
            lastName: lastName,
            houseNameNo: houseNameNo,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            town: town,
            county: county,
            postcode: postcode,
            country: country
        }

        //Include the product data so the mutation can use it
        props.transact({ variables: {
            productData,
            productVoucherCode,
            userContactDetails
        } });
    }

    const [email, setEmail] = useState(""); //Email could be pre-filled out if the user is logged-in
    const [salutation, setSalutation] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [houseNameNo, setHouseNameNo] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [town, setTown] = useState("");
    const [county, setCounty] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("");

    //If fields are modified after validation failure, clear validation error
    const customSetState = (e, fieldName) => {
        props.fieldErrors[fieldName] = null;

        switch (fieldName) {
            case 'email':
                setEmail(e.target.value);
            break;
            case 'salutation':
                setSalutation(e.target.value);
            break;
            case 'firstName':
                setFirstName(e.target.value);
            break;
            case 'lastName':
                setLastName(e.target.value);
            break;
            case 'houseNameNo':
                setHouseNameNo(e.target.value);
            break;
            case 'addressLine1':
                setAddressLine1(e.target.value);
            break;
            case 'addressLine2':
                setAddressLine2(e.target.value);
            break;
            case 'town':
                setTown(e.target.value);
            break;
            case 'county':
                setCounty(e.target.value);
            break;
            case 'postcode':
                setPostcode(e.target.value);
            break;
            case 'country':
                setCountry(e.target.value);
            break;
        }
    }

    const loadingMessage = props.loading ? <p>Loading</p> : null;

    return <Layout>
        <h1>Address</h1>
        <h3>As your basket totals zero and PayPal can't process zero priced transactions, we need to take your delivery details ourselves.</h3>
        <form onSubmit={handleSubmit} noValidate>

            <Input
                label="Email:"
                required={true}
                value={email}
                onChange={e => customSetState(e, 'email')}
                type="text"
                name="email"
                helper="Helper text"
                error={props.fieldErrors.email ? props.fieldErrors.email.message : null}
            />

            <Input
                label="Salutation:"
                required={true}
                value={salutation}
                onChange={e => customSetState(e, 'salutation')}
                type="select"
                options={[{id: 1, displayContent: 'Mr'}]}
                name="salutation"
                helper="Fill it out"
                error={props.fieldErrors.salutation ? props.fieldErrors.salutation.message : null}
            />

            <Input
                label="First name:"
                required={true}
                value={firstName}
                onChange={e => customSetState(e, 'firstName')}
                type="text"
                name="firstname"
                helper="Helper text"
                error={props.fieldErrors.firstName ? props.fieldErrors.firstName.message : null}
            />

            <Input
                label="Last name:"
                required={true}
                value={lastName}
                onChange={e => customSetState(e, 'lastName')}
                type="text"
                name="lastName"
                helper="Helper text"
                error={props.fieldErrors.lastName ? props.fieldErrors.lastName.message : null}
            />

            <Input
                label="House name / number:"
                required={true}
                value={houseNameNo}
                onChange={e => customSetState(e, 'houseNameNo')}
                type="text"
                name="houseNameNo"
                helper="Helper text"
                error={props.fieldErrors.houseNameNo ? props.fieldErrors.houseNameNo.message : null}
            />

            <Input
                label="Address line 1:"
                required={true}
                value={addressLine1}
                onChange={e => customSetState(e, 'addressLine1')}
                type="text"
                name="addressLine1"
                helper="Helper text"
                error={props.fieldErrors.addressLine1 ? props.fieldErrors.addressLine1.message : null}
            />

            <Input
                label="Address line 2:"
                value={addressLine2}
                onChange={e => customSetState(e, 'addressLine2')}
                type="text"
                name="addressLine2"
                helper="Helper text"
                error={props.fieldErrors.addressLine2 ? props.fieldErrors.addressLine2.message : null}
            />

            <Input
                label="Town:"
                required={true}
                value={town}
                onChange={e => customSetState(e, 'town')}
                type="text"
                name="town"
                helper="Helper text"
                error={props.fieldErrors.town ? props.fieldErrors.town.message : null}
            />

            <Input
                label="County:"
                required={true}
                value={county}
                onChange={e => customSetState(e, 'county')}
                type="text"
                name="county"
                helper="Helper text"
                error={props.fieldErrors.county ? props.fieldErrors.county.message : null}
            />

            <Input
                label="Postcode:"
                required={true}
                value={postcode}
                onChange={e => customSetState(e, 'postcode')}
                type="text"
                name="postcode"
                helper="Helper text"
                error={props.fieldErrors.postcode ? props.fieldErrors.postcode.message : null}
            />

            <Input
                label="Country:"
                required={true}
                value={country}
                onChange={e => customSetState(e, 'country')}
                type="text"
                name="country"
                helper="Helper text"
                error={props.fieldErrors.country ? props.fieldErrors.country.message : null}
            />

            <button>Send address and proceed</button>

            {loadingMessage}
            {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}

        </form>

    </Layout>
}

export default AddressForm;