import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

import Aux from '../../../hoc/Aux/Aux';
import Input from '../../../components/UI/Input/Input';
import Quantity from '../../../components/UI/Quantity/Quantity';
import BasketProductOption from './BasketProductOption.js';

import { FindOptionDetailPrice } from "../../../hoc/helpers/misc";

const BasketProductOptionsForm = (props) => {

    console.log('Render BasketProductOptionForm');

    const handleSubmit = (intent, event, quantityData = {}) => {
        event.preventDefault();
        console.log('About to submit form');

        const allProducts = [];

        //Need to loop through the products in the basket
        for (const basketProduct of props.products) {

            const product = {};
            product.productId = basketProduct.id;
            product.basketId = basketProduct.basketId;
            //if quantityData contains the current basketProduct, use the quantity in there
            //Intsead of state (as state won't have updated yet)
            if (quantityData && quantityData.name === product.basketId + "qty") {
                product.qty = quantityData.value;
            } else {
                product.qty = state[product.basketId + "qty"];
            }

            console.log('QTY will be sent as ', product.qty);
            product.options = [];

            //We need to access the form properties so we can build the JSON productData value that gets used by the mutation
            //Loop through form options
            for (const option of basketProduct.options) {

                console.log(`${option.name} = ${state[product.basketId + option.name]}`);
                
                //If the option's prerequisite(s) contain data
                let prerequisiteDataFilled = true;
                if(option.prerequisiteOptions.length) {
                    for (const prereq of option.prerequisiteOptions) {
                        const prereqName = basketProduct.options.find(o => o.id === prereq.id).name;
                        if (!state[product.basketId + prereqName]) {
                            console.log(prereqName + ' not filled out');
                            prerequisiteDataFilled = false;
                        }
                        
                    }
                }

                if (prerequisiteDataFilled) {
                    switch (option.type.controlType) {
                        case 'select':
                            console.log('Option =' + state[product.basketId + option.name]);
                            //if (state[option.name] === '0' || (!props.options.find(o => o.name === option.name).required && !state[option.name])) {
                            if (!state[product.basketId + option.name] || state[product.basketId + option.name] === '0') {
                                //Do nothing (don't add to request)
                            } else {
                                console.log('Adding to request');
                                product.options.push({
                                    "id": Number(option.id),
                                    "detailId": Number(state[product.basketId + option.name])
                                });
                            }
                            break;
                        default: //Default = text
                            product.options.push({
                                "id": Number(option.id),
                                //Get detailId (since there can be only one detail in a text option) from the props
                                "detailId": Number(basketProduct.options.find(o => o.name === option.name).details.find(d => d).id),
                                "detailContent": state[product.basketId + option.name]
                            });
                    }
                }
            
            } //End options loop

            allProducts.push(product); //Add product object to array
            
        } //End product loop

        const productData = JSON.stringify(allProducts);

        console.log('Submit basket');
        props.submitBasket({ variables: {
            productData,
            productVoucherCode,
            intent //Intent allows the API to tell the difference between a voucher being applied and the basket being submitted, although both use the same query
        } });
    } //End handleSubmit method

    const updateVoucherCode = (e) => {
        props.fieldErrors['voucher_code'] = null; //Clear validation errors

        //Update cookie voucher code
        //props.updateCookieVoucherCode(e.target.value);

        setProductVoucherCode(e.target.value);
    }

    //The below creates an object based on the product's inital options:
    //const [state, setState] = useState({ productBasketId = 12312312 option 1 name: "", productBasketId = 12312312 option 2 name: "" });
    let stateObject = {};
    let basePrice = 0;

    console.log('State object', stateObject);
    const [state, setState] = useState(stateObject);
    const [productVoucherCode, setProductVoucherCode] = useState(props.productVoucherCode);

    if (!props.loading) {

        console.log('props.products', props.products);

        //Loop through products so the state functions can be set up
        for (const product of props.products) {
            
            basePrice = product.basePrice;
        
            for (const option of product.options) {

                let optionPrice = 0;
        
                let cookieOption = null;

                if (product.basketOptions) {
                    cookieOption = product.basketOptions.find(cbpo => Number(cbpo.id) === Number(option.id));
                }
                console.log(cookieOption);
        
                if (cookieOption && cookieOption.selected) {
                    if (option.type.controlType === "select") {
                        stateObject[product.basketId + option.name] = cookieOption.selected.id;
                    } else {
                        stateObject[product.basketId + option.name] = cookieOption.selected.content;
                    }
                    //Find the price of the selected option so we can add it to the product base price
                    //console.log(option, cookieOption.selected.id);
                    optionPrice = FindOptionDetailPrice(option, cookieOption.selected.id);
                } else {
                    stateObject[product.basketId + option.name] = "";
                }
                
                basePrice += optionPrice;
        
            } //End options loop

            stateObject[product.basketId + "qty"] = product.basketQty; //Qty from basket

        } //End product loop

    }

    let totalBasketPrice = 0;

    /*
     * Now map products to create products to display
     */
    const products = props.products.map(product => {

        const updateQuantity = (e) => {
            const { name, value, event } = e.target;

            props.fieldErrors[product.basketId + name] = null;
            props.fieldErrors['voucher_code'] = null;
            //Also loop through all of this product's options and clear their fieldError
            for (const option of product.options) {
                props.fieldErrors[product.basketId + option.name] = null;
            }

            //Update cookie qty
            props.updateCookieProductQty(product.basketId, value);

            console.log('Set state');
            setState(prevState => ({ ...prevState, [product.basketId + name]: value }));

            handleSubmit('basket', event, { name: product.basketId + name, value: value }); 
        }

        const options = product.options.map(option => {

            //If the option's prerequisites are all empty, don't display or disable the input
            let enableOption = true; //Default to true
    
            for (const prereq of option.prerequisiteOptions) {
                const prereqOption = product.options.find(p => p.id === prereq.id);
                if (
                    (prereqOption.type.controlType === 'select' && prereqOption.required && state[product.basketId + prereqOption.name] === '') ||
                    (prereqOption.type.controlType === 'text' && state[product.basketId + prereqOption.name] === '')
                ) {
                    //set enabled to false and break out (as all need to be filled for the option to be enabled)
                    enableOption = false;
                    break;
                }
            }
    
            let displayValue = null;
            if (option.details.find(o => Number(o.id) === Number(state[product.basketId + option.name]))) {
                displayValue = option.details.find(o => Number(o.id) === Number(state[product.basketId + option.name])).displayContent;
            }

            return <BasketProductOption
                key={option.id}
                label={option.description}
                value={state[product.basketId + option.name]}
                displayValue={displayValue}
                type={option.type.controlType}
                error={props.fieldErrors[product.basketId + option.name] ? props.fieldErrors[product.basketId + option.name].message : null}
            />
        })

        const totalItemPrice = (product.price + product.basketTotalOptionsPrice) * state[product.basketId + "qty"];

        totalBasketPrice += (totalItemPrice - product.basketVoucherDiscount);

        console.log("Quantity of prod = ", state[product.basketId + "qty"]);

        let productVoucherText = null;
        if (product.basketVoucherDiscount > 0) {
            productVoucherText = <p>Voucher discount = &pound;{product.basketVoucherDiscount}</p>
        }

        return (
            <Aux key={product.basketId}>
                <h2>{product.name}</h2>
                {options}
                <Quantity
                    label='Quantity'
                    name='qty'
                    value={state[product.basketId + "qty"]}
                    quantityChange={e => updateQuantity(e)}
                    error={props.fieldErrors[product.basketId + "qty"] ? props.fieldErrors[product.basketId + "qty"].message : null}
                />
                <p>Total price per item = &pound;{(product.price + product.basketTotalOptionsPrice).toFixed(2)}</p>
                { productVoucherText }
                <p>Total items price = &pound;{(totalItemPrice - product.basketVoucherDiscount).toFixed(2)}</p>
                <Link to={{ pathname: `/${product.slug}/${product.basketId}`, state: { prevPathName: props.location.pathname } }}>Edit item</Link>
                <button onClick={(e) => props.removeProductFromBasket(product.basketId, e)}>Remove item from basket</button>
            </Aux>
        );

    }) //End product map

    const loadingMessage = props.loading ? <p>Loading</p> : null;
    
    console.log('Voucher code = ', productVoucherCode);

    let basketProductVoucherCode = <div>
        <Input
            label="Voucher code"
            value={productVoucherCode ? productVoucherCode : ""}
            onChange={e => updateVoucherCode(e)}
            type="text"
            name="voucher_code"
            error={props.fieldErrors['voucher_code'] ? props.fieldErrors['voucher_code'].message : null}
        />
        <button onClick={e => handleSubmit('basket', e)}>Apply voucher</button>
    </div>;
    
    let basketBottom = null;
    let basketSubmitButton = <div><button>Checkout with PayPal</button></div>

    if (!props.currentUser.id || props.currentUser.id === 0) {
        basketSubmitButton = <div><button>Checkout with PayPal as guest</button> or login</div>
    }

    if (props.products.length > 0) {
            basketBottom = <Aux>
            <p>TOTAL BASKET PRICE = &pound;{totalBasketPrice.toFixed(2)}</p>
            {basketSubmitButton}
        </Aux>
    }

    return <Aux>
        {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}
        <form onSubmit={e => handleSubmit('checkout', e)} noValidate>
            {products}
            {basketProductVoucherCode}
            {basketBottom}
        </form>
        {loadingMessage}
    </Aux>
    
}

export default withRouter(BasketProductOptionsForm);