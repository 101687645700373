import React from 'react';

import { BasketProductOptionWrapper } from './BasketProductOptionStyle';

const basketProductOption = (props) => {

    return <BasketProductOptionWrapper error={props.error}>
        <p>{props.label} = {props.type === "select" ? (props.displayValue ? props.displayValue : "No preference") : props.value}</p>
        <p className="helperText">{ props.error ? props.error : props.helper }</p>
    </BasketProductOptionWrapper>
    
};

export default basketProductOption;