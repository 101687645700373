import React from 'react';

//import styled from 'styled-components';
import { HomeWrapper } from './HomeStyle';

const home = () => {
    return <HomeWrapper>
        <h1>Papa Bear Wear</h1>
        <p>Coming soon...</p>
    </HomeWrapper>
}

export default home;