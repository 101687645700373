import React from 'react';

import { LabelWrapper, InputWrapper, SelectWrapper } from './InputStyle';


const input = (props) => {

    let input;

    //Select needs a default value setting

    switch (props.type) {
        case 'select':
            input = <SelectWrapper {...props}
                value={props.value ? props.value : (!props.required ? '0' : '-1') }
                >
                { props.required ? <option disabled={true} value='-1'>{props.defaultOptionText ? props.defaultOptionText : 'Select ' + props.name.replace(/_/g, ' ')}{props.required ? ' (required)' : ''}</option> : null }
                { !props.required ? <option value="0">No preference</option> : null}
                {
                    props.options.map(o => 
                    <option
                    key={o.id}
                    value={o.id}
                    disabled={o.stockQty === 0 ? true : false}>
                        {o.displayContent + (o.productPriceModifier > 0 ? ` (+ ${o.productPriceModifier})` : '') + (o.stockQty === 0 ? ' (out of stock)' : '')}
                    </option>)
                }
            </SelectWrapper>
            break;
        default: //Default = text
            input = <InputWrapper {...props}
                placeholder={props.required ? `Enter ${props.name.replace(/_/g, ' ')} (required)` : ''}
            />
    }

    return <LabelWrapper error={props.error}>
        <p>{props.label}</p>
        {input}
        <p className="helperText">{ props.error ? props.error : props.helper }</p>
    </LabelWrapper>
    
};

export default input;