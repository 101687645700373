import React from 'react';
import { withRouter } from "react-router";
import { useMutation } from '@apollo/react-hooks'

import { GetCookie, SetCookie } from "../../hoc/helpers/cookie";
import { MakeSimpleToken, GetCookieBasket } from "../../hoc/helpers/misc";

import { ProductWrapper } from './ProductStyle';

import Gallery from '../Gallery/Gallery';
import ProductOptionsForm from './ProductOptionsForm';

import { MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT } from '../../db_calls/products';

const Product = (props) => {
/*
    //Test wiping basket:
    const emptyCookieBasket = {
        products: []
    }
    SetCookie('pbwBasketContents', JSON.stringify(emptyCookieBasket), 31);
*/
    //Initialise cookie basket properties
    let cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));

    /*
     * If a basket_id url parameter exists, find the product in cookie and pass it to the ProductOptionsForm to fill out
     */
    let cookieBasketProduct = null;

    if (props.match.params.basketId) {
        cookieBasketProduct = cookieBasket.products.find(cb => cb.basketId === props.match.params.basketId);
    }

    const handleGalleryTest = (value) => {
        console.log('Test something');
    }

    /*
     * As only products get added to the basket, putting this function here
     * If a basketId exists, we're updating a product already in the basket
     * Only worry about updating the basket cookie here
     * If cookie and user basket DB record don't match, updating DB is handled by the User component
     */
    const updateBasket = (product) => {

        console.log("Wanting to add", product);

        if (cookieBasketProduct) {
            console.log('Exiting basket item, amending!');
            const foundIndex = cookieBasket.products.findIndex(cbp => cbp.basketId === props.match.params.basketId); //Find cookieBasketProduct in basket
            product.basketId = props.match.params.basketId;
            cookieBasket.products[foundIndex] = product; //Overwrite with new product
        } else {
            console.log('No basket item, making new one!');
            //If no basketId, create one!
            product.basketId = MakeSimpleToken(10);
            cookieBasket.products.push(product);
            console.log('Redirect to ' + product.basketId);
        }

        SetCookie('pbwBasketContents', JSON.stringify(cookieBasket), 31);

        console.log('Added to basket');
        console.log(cookieBasket);

        return product.basketId;
    }

    const [
        updateLoginUserBasketProduct,
        { loading, error, called, data }
    ] = useMutation(
        MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT,
        {
            onCompleted(data) {
                console.log('onCompleted');
                //If no errors, redirect to product page with the basket_id parameter
                if (data.updateLoginUserBasketProduct.products.find(d => d).errorCount === 0) {
                    const redirectUrl = updateBasket(data.updateLoginUserBasketProduct.products.find(d => d));
                    //And redirect
                    let redirect = `/${props.slug}/${redirectUrl}`;
                    //If previous page was /basket, redirect there
                    if (props.location.state && props.location.state.prevPathName === "/basket") {
                        redirect = props.location.state.prevPathName;
                    }

                    //Otherwise, just redirect to product page with cookie id added
                    props.history.push(redirect);
                }
            },
            onError(error) {
                //This is what will happen if there's a server error
                //Might not be able to use this though - as it fires on every other type of error too
            }
        }
    );

    let fieldErrors = {};

    if (error) {
        console.log(error.graphQLErrors);
        //Product option detail errors are different from other form validation errors
    }

    if (data) {
        //No query errors, but what about product validation errors?
        //We're only sending one product per request, so find the single result
        const validationResult = data.updateLoginUserBasketProduct.products.find(d => d);

        if (validationResult.errorCount > 0) {
            //Loop through validationResult.options
            console.log(props.options);
            validationResult.options.forEach(o => {
                if (o.errors.length) {
                    const firstError = o.errors.find(oe => oe);
                    //Check the given error code
                    //firstError.code
                    const errorOption = props.options.find(po => po.id === o.id);
                    /* //Build the allowed values (not used atm)
                        * const validOptions = firstError.relatedIds.map(r => props.options.find(po => po.id === o.id).details.find(pod => pod.id === r.id).displayContent);
                        * console.log(validOptions);
                        */
                    //if no error option exists (as will be the case with 'qty' errors), just add using the id as the name
                    fieldErrors[(errorOption ? errorOption.name : o.id)] = { message: firstError.message }
                }
            })

            //return <p>There were {validationResult.errorCount} errors</p>
        }
    }

    let productOptionsMutation;

    if (props.stockQty) { //If there's stock, show the options form
        productOptionsMutation = <ProductOptionsForm
            addToBasket={updateLoginUserBasketProduct}
            productId={props.id}
            productBasePrice={props.price}
            options={props.options}
            loading={loading}
            error={error}
            fieldErrors={fieldErrors}
            galleryImageTest={handleGalleryTest}
            cookieBasketProduct={cookieBasketProduct} />;
    } else {
        productOptionsMutation = <p>NO STOCK, NOTIFICATION HERE</p>
    }

    const productGallery = <Gallery
        images={props.media}
        />

    const inBasketMessage = cookieBasketProduct ? <h4>This product is currently in your basket</h4> : null;

    return (
        <ProductWrapper>
            <nav className="breadcrumb">
                <ul itemScope itemType="http://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">Home</li>
                    <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">product</li>
                </ul>
            </nav>
            <main itemProp="mainEntity" itemScope itemType="http://schema.org/Product">
                <section>
                    {productGallery}
                </section>
                <section>
                    <h2 itemProp="name">Product: {props.name} <span>from &pound;{props.price.toFixed(2)}</span></h2>
                    <h3>{props.subtitle}</h3>
                    {inBasketMessage}
                    <p itemProp="description">{props.description}</p>
                    {productOptionsMutation}
                </section>
            </main>

        </ProductWrapper>
    );
};

export default withRouter(Product);