import React from 'react';
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import Category from '../../components/Category/Category';
import Error from '../Error/Error';
import Layout from '../../hoc/Layout/Layout';
import Product from '../../components/Product/Product';

import { QUERY_PRODUCT_AND_CATEGORY_BY_SLUG } from '../../db_calls/products';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//Search for a matching category or product slug

const CategoryOrProduct = (props) => {

  const { loading, error, data } = useQuery(QUERY_PRODUCT_AND_CATEGORY_BY_SLUG(props.match.params.inputSlug));

  if (loading) return (
      <p>Loading...</p>
  ); //Needs to be surrounded by Layout
  if (error) return (
      <p>Error :(</p>
  ); //Same applies here

  //This is here temporarily while testing - so the ugly layout styling doesn't appear elsewhere!
  if (!data.productBySlug && !data.productCategoryBySlug) {
    return (
      <Error />
    )
  }

  let returnComponent = <Error />;

  //Was a product or category found?
  if (data.productBySlug) {

    returnComponent = <Product
    {...data.productBySlug} //Pass all object data as props
    />;

  } else if (data.productCategoryBySlug) {

    returnComponent = <Category
    name={data.productCategoryBySlug.name}
    products={data.productCategoryBySlug.products}/>;

  }

  return (
    <Layout>
      {returnComponent}
    </Layout>
  );

};

export default CategoryOrProduct;