import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks'

import Layout from '../../hoc/Layout/Layout';
import LoginForm from './LoginForm';
import LoginRedirect from './LoginRedirect';

import { GetCookie, SetCookie } from "../../hoc/helpers/cookie";

import { MUTATION_LOGIN_USER } from '../../db_calls/users';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';

const Login = (props) => {

    //None of this should be visible if there's already a user logged in (the same applies to other user forms)
    //Redirect to /account if already logged in

    const [
        loginUser,
        { loading, error, called, data }
    ] = useMutation(
        MUTATION_LOGIN_USER,
        {
            onCompleted({ loginUser }) {
                //User details entered correctly, set JWT cookie, valid for a month
                SetCookie('jwtAuthToken', loginUser.token, 31);
            }
        }
    );

    let fieldErrors = {};

    if (loading || error || !called) {

        if (error) {
            console.log(error.graphQLErrors);
    
            for (let graphQLErrorKey in error.graphQLErrors) {
                //Could be a can't find user error
                if (error.graphQLErrors[graphQLErrorKey].extensions.code) {
                    if (error.graphQLErrors[graphQLErrorKey].extensions.code === 404) {
                        fieldErrors['overallError'] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: <p>User Not found. Wrong password?</p>
                        }
                    }
                }
                //Could be a validation error
                //If exception(s) exist, loop through those
                if (error.graphQLErrors[graphQLErrorKey].extensions.exception) {
                    for (let invalidArgKey in error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs) {
                        fieldErrors[error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].param] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].msg
                        }
                    }
                }
            }

            //Other errors?
    
        }

        return <LoginForm
            login={loginUser}
            loading={loading}
            error={error}
            fieldErrors={fieldErrors}
            />;
    }

    //If no errors, login success!
    return <LoginRedirect
        user={data.loginUser.user}
        locationState={props.location.state} />

}

export default Login;