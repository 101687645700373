import React from 'react';

export const Airport = props => (
    <svg version={1} viewBox="0 0 566.93 566.93" {...props}>
      <path
        fill="fill"
        d="M81.838,309.97c35.709,0,71.419,0,107.128,0c63.811,0,127.623,0,191.434,0c27.812,0,55.625,0,83.438,0
			c12.258,0,22.586-10.024,22.667-22.343c0.081-12.196-10.085-22.667-22.343-22.667c-35.709,0-71.419,0-107.128,0
			c-63.812,0-127.623,0-191.435,0c-27.812,0-55.625,0-83.438,0c-12.257,0-22.585,10.025-22.667,22.343
      C59.414,299.499,69.58,309.97,81.838,309.97L81.838,309.97z
      M81.838,189.97c35.709,0,71.419,0,107.128,0c63.812,0,127.623,0,191.435,0c27.812,0,55.625,0,83.438,0
			c12.258,0,22.586-10.024,22.667-22.343c0.081-12.196-10.085-22.667-22.343-22.667c-35.709,0-71.419,0-107.128,0
			c-63.812,0-127.623,0-191.435,0c-27.812,0-55.625,0-83.438,0c-12.257,0-22.585,10.024-22.667,22.343
      C59.414,179.499,69.58,189.97,81.838,189.97L81.838,189.97z
      M81.838,431.97c35.709,0,71.419,0,107.128,0c63.812,0,127.623,0,191.435,0c27.812,0,55.625,0,83.438,0
			c12.258,0,22.586-10.024,22.667-22.343c0.081-12.196-10.085-22.667-22.343-22.667c-35.709,0-71.419,0-107.128,0
			c-63.812,0-127.623,0-191.435,0c-27.812,0-55.625,0-83.438,0c-12.257,0-22.585,10.024-22.667,22.343
			C59.414,421.499,69.58,431.97,81.838,431.97L81.838,431.97z"
      />
    </svg>
  );