import React from 'react';

import { Link } from 'react-router-dom';

import { GetCookie } from "../../../hoc/helpers/cookie";
import { GetCookieBasket } from "../../../hoc/helpers/misc";

//import { GuestBasketWrapper } from './GuestBasketStyle';

const basket = (props) => {

    //Get basketCount from cookie
    let cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));

    return <li>
        <Link to='/basket'>Cookie Basket</Link> ({cookieBasket.products.length})
        </li>
}

export default basket;