import { gql } from "apollo-boost";

export const QUERY_CURRENT_USER = gql`
{
  currentUser{
      id
      email
      basket {
          id
      }
  }
}
`;

export const QUERY_VALIDATE_UPDATE_USER_PASSWORD_TOKEN = (passwordUpdateToken) => gql`
{
  validateUpdateUserPasswordToken(password_update_token: "${passwordUpdateToken}"){
    message
  }
}
`;

/*
 * Mutations
 */

export const MUTATION_REGISTER_USER = gql`
mutation registerUser($email: String!, $password: String!) {
  registerUser(email: $email, password: $password) {
      message
  }
}
`;

export const MUTATION_CONFIRM_USER = gql`
mutation confirmUser($confirmation_token: String!) {
  confirmUser(confirmation_token: $confirmation_token) {
      message
  }
}
`;

export const MUTATION_UPDATE_USER_PASSWORD = gql`
 mutation updateUserPassword($password_update_token: String!, $password: String!) {
  updateUserPassword(password_update_token: $password_update_token, password: $password) {
      message
  }
}
`;

export const MUTATION_REQUEST_USER_PASSWORD_UPDATE = gql`
mutation requestUserPasswordUpdate($email: String!) {
  requestUserPasswordUpdate(email: $email) {
      message
  }
}
`;

export const MUTATION_LOGIN_USER = gql`
mutation loginUser($email: String!, $password: String!) {
  loginUser(email: $email, password: $password) {
      token
      user {
          id
          email
          basket {
              id
          }
      }
  }
}
`;