import { gql } from "apollo-boost";

export const MUTATION_TRANSACT = gql`
mutation transact($productData: String!, $productVoucherCode: String) {
    transact(productData: $productData, productVoucherCode: $productVoucherCode) {
        code
        url
        data
    }
}
`;

export const MUTATION_TRANSACT_WITH_ADDRESS = gql`
mutation transactWithAddress($productData: String!, $productVoucherCode: String, $userContactDetails: UserContactDetails!) {
    transactWithAddress(productData: $productData, productVoucherCode: $productVoucherCode, userContactDetails: $userContactDetails) {
        code
        url
        data
    }
}
`;