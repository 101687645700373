import styled from 'styled-components';

export const LabelWrapper = styled.label`

    display: block;
    text-align: left;

    .helperText {
        font-size: 0.8rem;
        color: ${props => props.error ? '#F00' : '#000'};
    }

`

export const InputWrapper = styled.input`

    background-color: ${props => props.error ? '#F00' : '#FFF'};
    border: 1px solid #DDD;
    border-bottom: 2px solid #000;

`

export const SelectWrapper = styled.select`

    background-color: ${props => props.error ? '#F00' : '#FFF'};
    border: 1px solid #DDD;
    border-bottom: 2px solid #000;

`

