import { gql } from "apollo-boost";

/*
 * QUERIES
 */

export const QUERY_PRODUCT_CATEGORIES = gql`
{
  productCategories{
    id
    name
    slug
  }
}
`;

export const QUERY_PRODUCT_BY_IDS = (idString) => gql`
{
  productsByIds(ids:"${idString}"){
      description
      features {
        content
        type {
            name
        }
      }
      id
      media {
        path
        extension
      }
      name
      options(orderBy:{column: "priority", direction: "DESC"}) {
      id
      dependentOptions {
          id
      }
      description
      details(orderBy:{column: "priority", direction: "DESC"}) {
          id
          content
          displayContent
          maxLength
          productPriceModifier
          priority
          stockQty
      }
      name
      prerequisiteOptions {
          id
      }
      required
      priority
      type {
          controlType
          name
      }
      }
      price
      slug
      stockQty
      subtitle
  }
}
`;

export const QUERY_PRODUCT_AND_CATEGORY_BY_SLUG = (inputSlug) => gql `
{
  productBySlug(slug:"${inputSlug}"){
    description
    features {
      content
      type {
        name
      }
    }
    id
    media {
      path
      extension
    }
    name
    options(orderBy:{column: "priority", direction: "DESC"}) {
      id
      dependentOptions {
        id
      }
      description
      details(orderBy:{column: "priority", direction: "DESC"}) {
        id
        content
        displayContent
        maxLength
        productPriceModifier
        priority
        stockQty
      }
      name
      prerequisiteOptions {
        id
      }
      required
      priority
      type {
        controlType
        name
      }
    }
    price
    slug
    stockQty
    subtitle
  }
  productCategoryBySlug(slug:"${inputSlug}"){
    id
    slug
    name
    products(orderBy:{column:"name", direction:"ASC"}) {
      id
      name
      slug
      stockQty
    }
  }
}
`;

/*
 * MUTATIONS
 */

export const MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT = gql`
mutation updateLoginUserBasketProduct($productData: String!) {
  updateLoginUserBasketProduct(productData: $productData) {
      products {
          productId
          options {
              id
              selected {
                  id
                  content
              }
              errors {
                  code
                  message
                  relatedIds {
                      id
                  }
              }
          }
          price
          qty
          totalOptionsPrice
          errorCount
      }
  }
}
`;

export const MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT_BASKET = gql`
mutation updateLoginUserBasketProduct($productData: String!, $productVoucherCode: String, $intent: String) {
  updateLoginUserBasketProduct(productData: $productData, productVoucherCode: $productVoucherCode, intent: $intent) {
      products {
          productId
          basketId
          options {
              id
              detailId
              detailContent
              selected {
                  id
                  content
              }
              errors {
                  code
                  message
                  relatedIds {
                      id
                  }
              }
          }
          price
          qty
          totalOptionsPrice
          errorCount
          totalVoucherDiscount
      }
      productVoucher {
          code
          errors {
              code
              message
          }
      }
      intent
  }
}
`;