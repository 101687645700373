import React, { useState } from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';
import Input from '../../components/UI/Input/Input';

const RequestForgottenAccountPasswordForm = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submit it');
        props.request({ variables: {
            email
        } });
    }

    const [email, setEmail] = useState("");

    //If fields are modified after validation failure, clear validation error
    const customSetState = (e, fieldName) => {
        props.fieldErrors[fieldName] = null;

        if (fieldName === 'email') {
            setEmail(e.target.value);
        }
    }

    const loadingMessage = props.loading ? <p>Loading</p> : null;

    return <Layout>
        <h1>Forgotten password request</h1>
        <form onSubmit={handleSubmit}>
            <Input
                label="Email:"
                value={email}
                onChange={e => customSetState(e, 'email')}
                type="text"
                name="email"
                helper="Helper text"
                error={props.fieldErrors.email ? props.fieldErrors.email.message : null}
            />

            <button>Request password reset</button>

            {loadingMessage}
            {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}

        </form>
    </Layout>
}

export default RequestForgottenAccountPasswordForm;