import React from 'react';
import { Link } from 'react-router-dom';

import { withRouter } from "react-router";

import { Airport } from '../../../icons/js/Airport';
import ToolbarButton from '../../Nagivation/Toolbar/ToolbarButton/ToolbarButton';

//import { UserAccountWrapper } from './UserAccountStyle';

const userAccount = (props) => {

    let content = <li>
    {props.currentUser.email} 
    <Link to={{ pathname: '/logout', state: { prevPathName: props.location.pathname } }}>Logout</Link>
</li>

    /*
     * In mobile view, just display the login link - as there's a register option on the login page
     */
    /*
    if (props.linkType === 'mobile') {
        content = <ToolbarButton clicked={props.clicked}>
            <Link to="/login">
                <Airport width={24} height={24} fill='#000'/>
            </Link>
        </ToolbarButton>
    }
*/
    return content;
}

export default withRouter(userAccount);