import React from 'react';

import { LabelWrapper, QuantityWrapper } from './QuantityStyle';

/*
 * Quantity control is a disabled text input and two buttons
 */

const quantity = (props) => {

    const quantityChangeHandler = (quantityAmend, e) => {
        //DONT ALLOW PLUS TO BE CLICKED IF FIELD HAS ANY ERRORS
        e.preventDefault();
        const updatedValue = props.value + quantityAmend;
        if (updatedValue > 0) {
            props.quantityChange({ target: {name: props.name, value: updatedValue, event: e} });
        }
    }

    const input = <QuantityWrapper {...props}
        disabled={true}
        onChange={props.omChangeEvent}
    />

    return <LabelWrapper error={props.error}>
        <p>{props.label}</p>
        <button onClick={(e) => quantityChangeHandler(-1, e)}>Minus</button>
        {input}
        <button onClick={(e) => quantityChangeHandler(1, e)}>Plus</button>
        <p className="helperText">{ props.error ? props.error : props.helper }</p>
    </LabelWrapper>
    
};

export default quantity;