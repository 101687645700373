import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import { useMutation, useQuery } from '@apollo/react-hooks'

import UserContext from '../../contexts/UserContext';

import Aux from '../../hoc/Aux/Aux';
import Layout from '../../hoc/Layout/Layout';
import BasketProductOptionsForm from '../../containers/Basket/BasketProductOptionsForm/BasketProductOptionForm';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { GetCookie, SetCookie } from "../../hoc/helpers/cookie";
import { GetCookieBasket } from "../../hoc/helpers/misc";

import { QUERY_PRODUCT_BY_IDS, MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT_BASKET } from '../../db_calls/products';

//import { TermsWrapper } from './TermsStyle';

const updateCookieProductQty = (basketId, qty) => {
    console.log('Update cookie qty', basketId, qty);
    const cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));
    const cookieProductIndex = cookieBasket.products.findIndex(cb => cb.basketId === basketId);
    cookieBasket.products[cookieProductIndex].qty = qty;
    SetCookie('pbwBasketContents', JSON.stringify(cookieBasket), 31);
}
/*
const updateCookieVoucherCode = (voucherCode) => {
    console.log('Update cookie voucher', voucherCode);
    const cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));
    if (cookieBasket.productVoucher) {
        cookieBasket.productVoucher.code = voucherCode;
    } else {
        cookieBasket.productVoucher = { code: voucherCode };
    }
    SetCookie('pbwBasketContents', JSON.stringify(cookieBasket), 31);
}
*/
const Basket = (props) => {

    const { currentUser } = useContext(UserContext);

    const cookieBasket = GetCookieBasket(GetCookie('pbwBasketContents'));

    const removeProductFromBasketHandler = (productBasketId, e) => {
        e.preventDefault();
        console.log('Remove from basket ' + productBasketId);

        //Filter out any basket product matching the given product id
        const newCookieBasketProducts = cookieBasket.products.filter(cbp => cbp.basketId !== productBasketId);
        //Re-write cookie
        cookieBasket.products = newCookieBasketProducts;
        SetCookie('pbwBasketContents', JSON.stringify(cookieBasket), 31);
        //Simply reload page?
        window.location.reload();
    }

    let basketHeader = <h2>Your basket is empty!</h2>;

    if (cookieBasket.products.length > 0) {

        let basketUserMsg = null;

        if (!currentUser.id || currentUser.id === 0) {
            basketUserMsg = <p>You're not logged in! Want to <Link to={{ pathname: '/login', state: { prevPathName: props.location.pathname } }}>login</Link> or register so you can track your purchases easily?</p>
        }

        basketHeader = <div>{basketUserMsg}
                <h2>Your basket contains:</h2>
            </div>
    }

    //Need a CSV of all basket ids so we have some product data to work with
    const basketIds = cookieBasket.products.map(({ productId }) => ( productId ));
    const basketIdString = `${basketIds.join(',')}`;
    
    const { loading: queryLoading, error: queryError, data: queryData } = useQuery(QUERY_PRODUCT_BY_IDS(basketIdString));

    //Mutation for submitting data from the combined ProductOptions
    const [
        updateLoginUserBasketProduct,
        { loading, error, called, data }
    ] = useMutation(MUTATION_UPDATE_LOGIN_USER_BASKET_PRODUCT_BASKET);

    let mutationData = data; //Need to be able to update this, so can't use as a constant

    if (queryLoading) return (
        <p>Loading...</p>
    ); //Needs to be surrounded by Layout
    if (queryError) return (
        <p>Error :(</p>
    ); //Same applies here
            
    let fieldErrors = {};

    if (error) {
        console.log(error.graphQLErrors);
        //Product option detail errors are different from other form validation errors
        //Needs stuff adding here (search for overallError for examples elsewhere) to handle server errors
    }

    //console.log(client.readQuery);

    let errorCount = 0; //Keep track of the number of errors in data

    //If the mutation hasn't been called (ie, the first time the basket page is loaded)
    if (!called) {

        console.log('LOOKING FOR ERRORS PASSED BY THE CHECKOUT API CALL');
        

        //If session value exists, and action (set by basket checkout) or basket is empty
        if (sessionStorage.getItem('checkoutError') && props.location.state && props.location.state.data !== null) {

            console.log('Checkout data errors found!', props.location.state.data);

            mutationData = {
                updateLoginUserBasketProduct: JSON.parse(props.location.state.data)
            }

            //Erase session data so the error doesn't appear on the next refresh
            sessionStorage.removeItem('checkoutError');

        }

    }


    if (mutationData) {

        let generalErrorMsg = mutationData.updateLoginUserBasketProduct.generalErrorMsg ? mutationData.updateLoginUserBasketProduct.generalErrorMsg : "There was a problem with an item in your basket :(";

        console.log('Basket, mutation run, data returned');
        //No query errors, but what about product validation errors?
        //Loop through validation results
        for (const validationResult of mutationData.updateLoginUserBasketProduct.products) {
            console.log('Validation results = ', validationResult);
            if (validationResult.errorCount > 0) {
                errorCount += validationResult.errorCount;
                //Loop through validationResult.options
                for (const o of validationResult.options) {
                    if (o.errors.length) {
                        const firstError = o.errors.find(oe => oe);
                        //Check the given error code
                        //firstError.code
                        console.log(queryData.productsByIds, validationResult.productId);
                        const errorOption = queryData.productsByIds.find(dp => Number(dp.id) === Number(validationResult.productId)).options.find(po => po.id === o.id);
                        //if no error option exists (as will be the case with 'qty' errors), just add using the id as the name
                        fieldErrors[(errorOption ? validationResult.basketId + errorOption.name : validationResult.basketId + o.id)] = { message: firstError.message }
                    }
                }
                fieldErrors['overallError'] = {
                    graphQLErrorKey: null,
                    message: <p>{generalErrorMsg}</p>
                }
                //return <p>There were {validationResult.errorCount} errors</p>
            }
        }
        if (mutationData.updateLoginUserBasketProduct.generalErrorMsg) {
            fieldErrors['overallError'] = {
                graphQLErrorKey: null,
                message: <p>{generalErrorMsg}</p>
            }
        }

        //Deal with voucher code errors, also need to go into fieldErrors
        if (mutationData.updateLoginUserBasketProduct.productVoucher.errors && mutationData.updateLoginUserBasketProduct.productVoucher.errors.length) {
            errorCount += mutationData.updateLoginUserBasketProduct.productVoucher.errors.length;

            const firstError = mutationData.updateLoginUserBasketProduct.productVoucher.errors.find(oe => oe);

            fieldErrors['voucher_code'] = {
                graphQLErrorKey: firstError.code,
                message: firstError.message
            }
        }

    }

    const basketProducts = [];

    for (const basketProductItem of cookieBasket.products) {

        console.log('BasketproductId', queryData.productsByIds);

        const basketItemData = queryData.productsByIds.find(dp => dp.id === basketProductItem.productId);
        
        console.log('Basketdata', basketItemData);

        let basketProduct = {...basketItemData}
        
        basketProduct.basketId = basketProductItem.basketId
        basketProduct.basketQty = basketProductItem.qty
        basketProduct.basketOptions = basketProductItem.options
        basketProduct.basketPrice = basketProductItem.price
        basketProduct.basketTotalOptionsPrice = basketProductItem.totalOptionsPrice
        
        let basketProductVoucherDiscount = 0;
        //If on a request containing data, find true voucher discount
        if (mutationData) {
            // Get the voucher discount from the response data
            const basketProductVoucherResult = mutationData.updateLoginUserBasketProduct.products.find(p => p.id === basketProductItem.id);
            if (basketProductVoucherResult) {
                basketProductVoucherDiscount = basketProductVoucherResult.totalVoucherDiscount;
            }
        }
        basketProduct.basketVoucherDiscount = basketProductVoucherDiscount;

        basketProducts.push(basketProduct);
    }

    let basketProductOptionsMutation = null;

    if ( called && mutationData && errorCount === 0 && mutationData.updateLoginUserBasketProduct.intent === 'checkout') {

        data.updateLoginUserBasketProduct.paymentMethod = 'paypal'; //Add a payment method to the data object

        //Update cookie a final time so that it contains all the detailId / detailContent props added by the BasketProductOptionsForm
        SetCookie('pbwBasketContents', JSON.stringify(mutationData.updateLoginUserBasketProduct), 31);

        //Redirect to checkout, passing the returned data from the mutation
        //Note, the push means /basket gets pushed into history, instead of being replaced by /checkout
        return <Redirect push to={{
            pathname: '/checkout',
            state: {
                data: mutationData.updateLoginUserBasketProduct
            }
        }} />

    } else {

        basketProductOptionsMutation = <BasketProductOptionsForm
        submitBasket={updateLoginUserBasketProduct}
        removeProductFromBasket={removeProductFromBasketHandler}
        products={basketProducts}
        //productVoucherCode={(cookieBasket.productVoucher ? cookieBasket.productVoucher.code : null)}
        productVoucherCode={(mutationData && mutationData.updateLoginUserBasketProduct.productVoucher.code ? mutationData.updateLoginUserBasketProduct.productVoucher.code : null)}
        updateCookieProductQty={updateCookieProductQty}
        //updateCookieVoucherCode={updateCookieVoucherCode}
        currentUser={currentUser}
        error={error}
        loading={loading}
        fieldErrors={fieldErrors}
        />;

    }

    return <Layout>
        {basketHeader}
        {basketProductOptionsMutation}
    </Layout>

}

export default Basket;