import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import { Redirect } from 'react-router-dom'

import { EraseCookie } from "../../hoc/helpers/cookie";
import UserContext from '../../contexts/UserContext';

const Logout = (props) => {

    let redirectUrl = '/';

    //Redirect to the previous location, if it's given
    //Blacklist urls that we don't want to redirect to
    const disallowedRedirects = ['/logout'];

    if (props.location.state && disallowedRedirects.indexOf(props.location.state.prevPathName) === -1) {
        redirectUrl = props.location.state.prevPathName;
    }

    console.log(`Going to do a redirect to ${redirectUrl}`);

    EraseCookie('jwtAuthToken');
    console.log('Cookie erased'); //Should this be blanked instead of Erase

    //We also need to erase the curentUser from context
    const { setCurrentUser } = useContext(UserContext);
    setCurrentUser({});

    return <Redirect to={redirectUrl} />

}

export default withRouter(Logout);