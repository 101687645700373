import React from 'react';
import { Link } from 'react-router-dom';

const listItem = (props) => {

    let link = <Link to={props.linkTo}>{props.children}</Link>;

    if (props.linkType === 'external') {
        link = <a href={props.linkTo}>{props.children}</a>;
    }

    return (
        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            {link}
        </li>
    );
};

export default listItem;