import styled from 'styled-components';
import { device } from '../../../hoc/helpers/device';

export const DrawerWrapper = styled.div`

    position: fixed;
    width: 280px;
    max-width: 70%;
    height: calc(100% - 56px); //Minus toolbar height
    left: 0;
    top: 0;
    z-index: 100;
    background-color: #00F;
    box-sizing: border-box;

    transition: transform 0.3s ease-out;

    transform: ${props => props.open ? 'translateX(0)' : 'translateX(-100%)'};

    @media ${device.tablet} {
        display: none;
    }

    @media ${device.laptop} {
        display: none;
    }

    @media ${device.desktop} {
        display: none;
    }

    nav {
        display: block;
        height: calc(100% - 40px); //Minus close drawer height
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }

    .closeDrawerArea {
        height: 40px;
        text-align: right;

        p {
            margin: 0;
        }

    }

`;