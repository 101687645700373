import React, { Component, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks'

import { UserProvider } from './contexts/UserContext';

import { EraseCookie } from "./hoc/helpers/cookie";

import AboutUs from './containers/AboutUs/AboutUs';
import Basket from './containers/Basket/Basket';
import CategoryOrProduct from './containers/CategoryOrProduct/CategoryOrProduct';
import Checkout from './containers/Checkout/Checkout';
import CheckoutCancelled from './containers/Checkout/CheckoutCancelled/CheckoutCancelled';
import CheckoutComplete from './containers/Checkout/CheckoutComplete/CheckoutComplete';
import ConfirmAccount from './containers/ConfirmAccount/ConfirmAccount';
import Error from './containers/Error/Error';
import ForgottenAccountPasswordPage from './containers/ForgottenAccountPassword/ForgottenAccountPasswordPage';
import Home from './containers/Home/Home';
import Layout from './hoc/Layout/Layout';
import Login from './containers/Login/Login';
import Logout from './containers/Logout/Logout';
import NewAccount from './containers/NewAccount/NewAccount';
import RequestForgottenAccountPassword from './containers/RequestForgottenAccountPassword/RequestForgottenAccountPassword';
import Terms from './containers/Terms/Terms';

import { QUERY_CURRENT_USER } from './db_calls/users';

const App = (props) => {

    console.log('App.js rendering...');

    const [ currentUser, setCurrentUser ] = useState({});

    const { loading, error, data } = useQuery(QUERY_CURRENT_USER);

    console.log('Current user is', currentUser);

    if (loading) {
      console.log('App.js says User loading...');
    } else {
      //If loaded
      //If not authenticaed, or no auth token
      if (!data || !data.currentUser || data.currentUser.id === '0' || !props.jwtAuthToken) {
        //Delete any user cookie that might exist?
        console.log('App.js says Cookie not right...');
      } else {
        //if authenticated, and nothing currently in currentUser
        if (!currentUser) {
          console.log('Setting current user to', data.currentUser);
          setCurrentUser(data.currentUser);
        }
      }
    }

    if (error) {
      EraseCookie('jwtAuthToken'); //Delete any user cookie (as they could be invalid for some reason)
      console.log('App.js says User error...');
    }

    return (
      <UserProvider value={{currentUser, setCurrentUser}}>
        <Switch>
          <Route path="/error" component={Error} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/basket" component={Basket} />
          <Route path="/checkout/cancelled" component={CheckoutCancelled} />
          <Route path="/checkout/complete" component={CheckoutComplete} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/account/new-account" component={NewAccount} />
          <Route path="/account/confirm-account/:confirmationToken" component={ConfirmAccount} />
          <Route path="/account/forgotten-password" component={RequestForgottenAccountPassword} />
          <Route path="/account/forgotten-password/:passwordUpdateToken" component={ForgottenAccountPasswordPage} />
          <Route path="/about" component={AboutUs} />
          <Route path="/terms" component={Terms} />
          <Route exact path="/" component={Home} />
          <Route path="/:inputSlug?/:basketId?" component={CategoryOrProduct} />
        </Switch>
      </UserProvider>
    );
}

export default App;

/*
      //Should look like this
      <ApolloProvider client={client}>
      <Layout>
        <Switch>
          <Route path="/about" component={AboutUs} />
          <Route path="/" component={Home} />
        </Switch>
      </Layout>
      </ApolloProvider>
      */