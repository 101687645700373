import styled from 'styled-components';
import { device } from '../../../hoc/helpers/device';

export const NavigationItemsWrapper = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.tablet} {
        flex-direction: row;
    }

    @media ${device.laptop} {
        flex-direction: row;
    }

    @media ${device.desktop} {
        flex-direction: row;
    }

`