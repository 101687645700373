import React from 'react';

import { ToolbarButtonWrapper } from './ToolbarButtonStyle';

const toolbarButton = (props) => (
    <ToolbarButtonWrapper onClick={props.clicked}>
        {props.children}
    </ToolbarButtonWrapper>
)

export default toolbarButton;