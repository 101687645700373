import React from 'react';

import Aux from '../../../hoc/Aux/Aux';
import { HeaderWrapper } from './HeaderStyle';
import NavigationItems from '../NavigationItems/NavigationItems';
import User from '../../User/User';

const header = (props) => {

    console.log('Header');

    const user = <User />

    return (
        <HeaderWrapper navBarLocked={props.navBarLocked}>
            <section>
                <div>
                    &nbsp;
                </div>
                <div className="head">
                    Logo
                </div>
                <div>
                    { props.showUser ? user : null }
                </div>
            </section>
            <nav>
                <NavigationItems />
            </nav>
        </HeaderWrapper>
    );
};

export default header;