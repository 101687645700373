import React, { useEffect } from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

/*
 * All this is doing is presenting a page seen very briefly to trigger the confirm user mutation
 */

const ConfirmAccountPage = (props) => {

    useEffect(() => {
        props.confirm({ variables: {
            confirmation_token: props.token
        } });
    });
    
    return <Layout>
        <p>LOADING</p>
    </Layout>
}

export default ConfirmAccountPage;