import styled from 'styled-components';
import { device } from '../../../hoc/helpers/device';

export const ProductWrapper = styled.div`

    background-color: #BBB;
    border: 1px solid #000;

    box-sizing: border-box;

    width: 50%;

    @media ${device.tablet} {

        width: 33%;

    }

    @media ${device.laptop} {

        width: 33%;

    }

    @media ${device.desktop} {

        width: 25%;

    }

`