import React from 'react';

import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../../UI/Backdrop/Backdrop';
import { DrawerWrapper } from './DrawerStyle';
import NavigationItems from '../../Nagivation/NavigationItems/NavigationItems';

const drawer = (props) => {

    console.log(props.open);

    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.drawCloseClicked} />
            <DrawerWrapper open={props.open}>
                <div className="closeDrawerArea">
                    <p onClick={props.drawCloseClicked}>CLOSE X</p>
                </div>
                <nav id="drawer_nav" className="scrollArea">
                    Drawer
                    <NavigationItems />
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                    <p>Testing for length</p>
                </nav>
            </DrawerWrapper>
        </Aux>
    );
};

export default drawer;