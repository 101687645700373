import React from 'react';

import Aux from '../..//hoc/Aux/Aux';
import { CategoryWrapper } from './CategoryStyle';
import Product from './Product/Product';

const category = (props) => {

    const products = props.products.map(({ id, name, slug }) => (
        <Product
          key={id}
          name={name}
          slug={slug}
        />
      ));

    return (
        <Aux>
        <div>
          Filter options go here
        </div>
        <CategoryWrapper itemScope itemType="http://schema.org/ItemList http://schema.org/Product">
            <h2 itemProp="name">Category: {props.name}</h2>
            <p>Products:</p>
            <section>
              {products ? products : "No products found"}
            </section>
            
        </CategoryWrapper>
        </Aux>
    );
};

export default category;