import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";


import { Airport } from '../../../icons/js/Airport';
import ToolbarButton from '../../Nagivation/Toolbar/ToolbarButton/ToolbarButton';

//import { GuestAccountWrapper } from './GuestAccountStyle';

const guestAccount = (props) => {

    let content = <li>
    <Link to={{ pathname: '/login', state: { prevPathName: props.location.pathname } }}>Login</Link> or <Link to="/account/new-account">Register</Link>
</li>

    /*
     * In mobile view, just display the login link - as there's a register option on the login page
     */
    if (props.linkType === 'mobile') {
        content = <ToolbarButton clicked={props.clicked}>
            <Link to={{ pathname: '/login', state: { prevPathName: props.location.pathname } }}>
                <Airport width={24} height={24} fill='#000'/>
            </Link>
        </ToolbarButton>
    }

    return content;
}

export default withRouter(guestAccount);