import React from 'react';
import { useMutation } from '@apollo/react-hooks'

import Layout from '../../hoc/Layout/Layout';
import RequestForgottenAccountPasswordForm from './RequestForgottenAccountPasswordForm';
import RequestForgottenAccountPasswordSuccess from './RequestForgottenAccountPasswordSuccess';

import { MUTATION_REQUEST_USER_PASSWORD_UPDATE } from '../../db_calls/users';

// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';

const RequestForgottenAccountPassword = (props) => {

    const [
        requestUserPasswordUpdate,
        { loading, error, called, data }
    ] = useMutation(MUTATION_REQUEST_USER_PASSWORD_UPDATE);

    let fieldErrors = {};

    if (loading || error || !called) {

        if (error) {
            console.log(error.graphQLErrors);

            for (let graphQLErrorKey in error.graphQLErrors) {
                //Could be a user already exists error
                if (error.graphQLErrors[graphQLErrorKey].extensions.code) {
                    if (error.graphQLErrors[graphQLErrorKey].extensions.code === 409) {
                        fieldErrors['overallError'] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: <p>User already exists. Forgotten password?</p>
                        }
                    }
                }
                //Could be a validation error
                //If exception(s) exist, loop through those
                if (error.graphQLErrors[graphQLErrorKey].extensions.exception) {
                    for (let invalidArgKey in error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs) {
                        fieldErrors[error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].param] = {
                            graphQLErrorKey: graphQLErrorKey,
                            message: error.graphQLErrors[graphQLErrorKey].extensions.exception.invalidArgs[invalidArgKey].msg
                        }
                    }
                }
            }

            //Other errors?
        }

        return <RequestForgottenAccountPasswordForm
        request={requestUserPasswordUpdate}
        loading={loading}
        error={error}
        fieldErrors={fieldErrors}
        />;

    }

    //If no errors, success!
    return <RequestForgottenAccountPasswordSuccess />

}

export default RequestForgottenAccountPassword;