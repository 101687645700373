import React from 'react';

import ListItem from './Item/ListItem';

const unorderedList = (props) => {

    const listItems = props.listItems.map(({ text, type, url }, index) => (
        <ListItem key={index} linkType={type} linkTo={url}>
            {text}
        </ListItem>
      ));

    return (
        <ul itemScope itemType="http://schema.org/ItemList">
            {listItems}
        </ul>
    );
};

export default unorderedList;