import styled from 'styled-components';
import { device } from '../../../hoc/helpers/device';

export const ToolbarWrapper = styled.nav`

    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 4px 0;
        padding: 0;
    }

    position: fixed;

    bottom: 0;

    background-color: #AAA;
    text-align: center;

    width: 100%
    height: 56px;

    z-index: 200;

    @media ${device.tablet} {
        display: none;
    }

    @media ${device.laptop} {
        display: none;
    }

    @media ${device.desktop} {
        display: none;
    }

`