import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

//import { LoginWrapper } from './LoginStyle';
import Input from '../../components/UI/Input/Input';

const LoginForm = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submit');
        props.login({ variables: {
            email,
            password
        } });
    }

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    //If fields are modified after validation failure, clear validation error
    const customSetState = (e, fieldName) => {
        props.fieldErrors[fieldName] = null;

        if (fieldName === 'email') {
            setEmail(e.target.value);
        }
        if (fieldName === 'password') {
            setPassword(e.target.value);
        }
    }

    const loadingMessage = props.loading ? <p>Loading</p> : null;

    return <Layout>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
            <Input
                label="Email:"
                value={email}
                onChange={e => customSetState(e, 'email')}
                type="text"
                name="email"
                helper="Helper text"
                error={props.fieldErrors.email ? props.fieldErrors.email.message : null}
            />

            <Input
                label="Password:"
                value={password}
                onChange={e => customSetState(e, 'password')}
                type="password"
                name="password"
                helper="Fill it out"
                error={props.fieldErrors.password ? props.fieldErrors.password.message : null}
            />

            <Link to="/account/forgotten-password">
                Forgotten password?
            </Link>

            <button>Send data</button>

            {loadingMessage}
            {props.fieldErrors.overallError ? props.fieldErrors.overallError.message : null}

        </form>

        <p>No account? <Link to="/account/new-account">Register a new account</Link></p>

    </Layout>
}

export default LoginForm;