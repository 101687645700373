import React from 'react';

import Layout from '../../hoc/Layout/Layout';
// Layout is only here so the layout component can be tested
// It would usually be applied to the App

import { TermsWrapper } from './TermsStyle';

const aboutUs = () => {
    return <Layout>
      <TermsWrapper>
    <p>Terms...</p>
    </TermsWrapper>
  </Layout>
}

export default aboutUs;