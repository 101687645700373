import React from 'react';

import { Airport } from '../../../icons/js/Airport';
import ToolbarButton from './ToolbarButton/ToolbarButton';
import { ToolbarWrapper } from './ToolbarStyle';
import User from '../../User/User';


const toolbar = (props) => (
    <ToolbarWrapper>
        <User
            linkType="mobile"
            clicked={props.drawerCloseClicked}>
            <ToolbarButton clicked={props.drawerToggleClicked}>
                <Airport
                    width={24}
                    height={24}
                    fill='#000'/>
            </ToolbarButton>
        </User>
    </ToolbarWrapper>
);

export default toolbar;